<template>
  <div class="order-status-selector">
    <el-select
      size="mini"
      v-model="value"
      placeholder="请选择"
      @change="selectChange"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: "orderStatusSelector",
  data() {
    return {
      value: null,
      options: [
        { value: 0, label: "报单待审核" },
        { value: 1, label: "待领取" },
        { value: 2, label: "待执行" },
        { value: 3, label: "执行中" },
        { value: 4, label: "待执行人领导审批" },
        { value: 5, label: "已完成" },
        { value: 6, label: "报单被驳回" },
        { value: 7, label: "验收被驳回" },
        { value: 8, label: "报单已撤销" },
        { value: 9, label: "己关单" },
      ],
    };
  },
  created() {
    this.initDefault();
  },
  methods: {
    initDefault() {
      this.value = 5;
      this.selectChange(5);
    },
    selectChange(value) {
      this.$emit(
        "selectChange",
        this.options.filter((item) => item.value === value)[0]
      );
    },
  },
};
</script>

<style lang="less" scoped>
.order-status-selector {
}
</style>
<template>
  <div class="order-inspection-visualization app-container">
    <div class="search-line">
      <WaterEnergySearch type="rwork" @searchParamChange="searchParamChange" />
    </div>
    <div class="card-container relationship-container">
      <div class="main-title">巡检计划/点位/子巡检关系图</div>
      <div class="chart-container">
        <MainRelationShip />
      </div>
      <div class="chart-container">
        <SecondRelationShip />
      </div>
    </div>
    <div class="card-container">
      <!-- 各巡检工单类别的月度数量概况 -->
      <MonthOrderInspection />
    </div>
    <div class="card-container">
      <el-row :gutter="30">
        <el-col :span="8">
          <!-- 各巡检工单类别的月度占比概况 -->
          <OrderInspectionProportion />
        </el-col>
        <el-col :span="16">
          <!-- 巡检接单人月度接单数 -->
          <InspectionMonthlyReceived />
        </el-col>
      </el-row>
    </div>
    <div class="card-container">
      <el-row :gutter="30">
        <el-col :span="12">
          <!-- 各巡检工单类别的不合格工单占比概况（共有 17 单不合格工单） -->
          <UnqualifiedWorkOrders />
        </el-col>
        <el-col :span="12">
          <!-- 各巡检工单类别的超时工单占比概况（共有 415 单超时工单） -->
          <TimeoutWorkOrders />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import WaterEnergySearch from "@/components/WaterEnergySearch";
import MainRelationShip from "./components/MainRelationShip.vue";
import SecondRelationShip from "./components/SecondRelationShip.vue";
import MonthOrderInspection from "./components/monthOrderInspection.vue";
import OrderInspectionProportion from "./components/orderInspectionProportion.vue";
import InspectionMonthlyReceived from "./components/inspectionMonthlyReceived.vue";
import UnqualifiedWorkOrders from "./components/unqualifiedWorkOrders.vue";
import TimeoutWorkOrders from "./components/timeoutWorkOrders.vue";
export default {
  name: "orderInspectionVisualization",
  components: {
    WaterEnergySearch,
    MainRelationShip,
    SecondRelationShip,
    MonthOrderInspection,
    OrderInspectionProportion,
    InspectionMonthlyReceived,
    UnqualifiedWorkOrders,
    TimeoutWorkOrders,
  },
  methods: {
    searchParamChange(params) {
      this.$eventBus.$emit("orderInspectionVisualization", params);
    },
  },
};
</script>

<style lang="less" scoped>
.order-inspection-visualization {
  margin: 10px 20px 20px 40px;
  background: none;
  padding-left: 0;
  .card-container {
    margin-top: 20px;
  }
  .relationship-container {
    height: 628px;
    background: #fff;
    border-radius: 10px;
    padding: 14px 20px;
    .main-title {
      font-size: 16px;
      font-weight: bold;
      color: #152c5b;
    }
    .chart-container {
      height: 240px;
      margin: 20px 0 30px;
    }
  }
}
</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "month-order-inspection" },
    [
      _c("WaterEnergyTitle", {
        attrs: { title: "各巡检工单类别的月度数量概况" },
      }),
      _c("OrderStatusSelect", {
        staticClass: "status-selector",
        on: { selectChange: _vm.selectChangeHandler },
      }),
      _c("div", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.chartLoading,
            expression: "chartLoading",
          },
        ],
        staticClass: "chart-container",
        attrs: { id: "monthOrderInspection" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="month-order-inspection">
    <WaterEnergyTitle title="各巡检工单类别的月度数量概况" />
    <OrderStatusSelect
      class="status-selector"
      @selectChange="selectChangeHandler"
    />
    <div
      class="chart-container"
      v-loading="chartLoading"
      id="monthOrderInspection"
    ></div>
  </div>
</template>

<script>
import { findOrderInspectionByStatus } from "@/api/ruge/bms/rdata/orderInspectionVisualization";
import WaterEnergyTitle from "@/components/WaterEnergyTitle";
import OrderStatusSelect from "@/components/OrderStatusSelect";
import * as echarts from "echarts";
export default {
  name: "monthOrderInspection",
  components: {
    WaterEnergyTitle,
    OrderStatusSelect,
  },
  data() {
    return {
      chartLoading: false,
      selectItem: {},
      resourceList: [],
    };
  },
  mounted() {
    this.$eventBus.$on("orderInspectionVisualization", (params) => {
      this.initDatas(params);
    });
  },
  methods: {
    selectChangeHandler(selectItem) {
      this.selectItem = selectItem;
      this.displayDatas();
    },
    getCurrentStatusStr(chartInspectionName) {
      const { label } = this.selectItem;
      for (let i = 0; i < this.resourceList.length; i++) {
        const { inspectionName, status, orderCount } = this.resourceList[i];
        if (chartInspectionName === inspectionName && status === label) {
          return `${label}: ${orderCount}个`;
        }
      }
      return `${label}: 0个`;
    },
    initDatas(params) {
      this.chartLoading = true;
      findOrderInspectionByStatus(params)
        .then((res) => {
          this.resourceList = res;
          this.displayDatas(res);
        })
        .finally(() => {
          this.chartLoading = false;
        });
    },
    displayDatas() {
      let xAxisList = [];
      let valueList = [];
      const { label } = this.selectItem;
      this.resourceList.forEach((item) => {
        if (!xAxisList.includes(item.inspectionName)) {
          xAxisList.push(item.inspectionName);
          valueList.push(this.getCountByStatus(item.inspectionName, label));
        }
      });
      this.initCharts(xAxisList, valueList);
    },
    getCountByStatus(itemName, itemStatus) {
      for (let i = 0; i < this.resourceList.length; i++) {
        const { inspectionName, status, orderCount } = this.resourceList[i];
        if (itemName === inspectionName && itemStatus === status) {
          return orderCount;
        }
      }
      return 0;
    },
    initCharts(xAxisList, valueList) {
      const chartDom = document.getElementById("monthOrderInspection");
      this.myChart = echarts.init(chartDom);
      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "none",
          },
          backgroundColor: "#cccccc80",
          textStyle: {
            color: "#000000",
          },
          formatter: (params) => {
            const { axisValue } = params[0];
            const currentStatusStr = this.getCurrentStatusStr(axisValue);
            return `${axisValue}</br>${currentStatusStr}`;
          },
        },
        grid: {
          left: "40",
          right: "10",
          bottom: "20",
        },
        xAxis: {
          type: "category",
          data: xAxisList || [],
          axisLabel: {
            interval: 0,
            showMinLabel: false,
            formatter: (value, index) => {
              return `${value.substring(0, 2)}...`;
            },
          },
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "点位数",
            type: "bar",
            data: valueList,
            itemStyle: {
              normal: {
                color: "#1A4CEC", // 定义柱形的背景色
                barBorderRadius: [10, 10, 0, 0], // 定义柱形的圆角
              },
            },
          },
        ],
      };
      this.myChart.setOption(option, false);
    },
  },
};
</script>

<style lang="less" scoped>
.month-order-inspection {
  height: 270px;
  background: #ffffff;
  border-radius: 10px;
  padding: 14px 20px;
  position: relative;
  .status-selector {
    position: absolute;
    right: 20px;
    top: 14px;
  }
  .chart-container {
    width: 100%;
    height: 210px;
    margin-top: 10px;
  }
}
</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "inspection-monthly-received" },
    [
      _c("WaterEnergyTitle", { attrs: { title: "巡检接单人月度接单数" } }),
      _c("OrderStatusSelect", {
        staticClass: "status-selector",
        on: { selectChange: _vm.selectChangeHandler },
      }),
      _c("div", {
        staticClass: "chart-container",
        attrs: { id: "inspectionMonthlyReceived" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
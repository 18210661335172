var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "unqualified-work-order" },
    [
      _c("WaterEnergyTitle", {
        attrs: {
          title: `各巡检工单类别的不合格工单占比概况（共有 ${_vm.totalCount} 单不合格工单）`,
        },
      }),
      _c("div", {
        staticClass: "chart-container",
        attrs: { id: "unqualifiedWorkOrder" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
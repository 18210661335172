<template>
  <div class="timeout-work-order">
    <WaterEnergyTitle
      :title="`各巡检工单类别的超时工单占比概况（共有 ${totalCount} 单超时工单）`"
    />
    <div class="chart-container" id="timeoutWorkOrder"></div>
  </div>
</template>

<script>
import { findInspectionOverTimeWorkOrders } from "@/api/ruge/bms/rdata/orderInspectionVisualization";
import WaterEnergyTitle from "@/components/WaterEnergyTitle";
import * as echarts from "echarts";
export default {
  name: "timeoutWorkOrder",
  components: {
    WaterEnergyTitle,
  },
  data() {
    return { totalCount: 9 };
  },
  mounted() {
    this.$eventBus.$on("orderInspectionVisualization", (params) => {
      this.currentParams = params;
      this.initDatas(params);
    });
  },
  methods: {
    initDatas(params) {
      findInspectionOverTimeWorkOrders(params).then((res) => {
        this.displayDatas(res);
      });
    },
    initCharts(datas) {
      const chartDom = document.getElementById("timeoutWorkOrder");
      this.myChart = echarts.init(chartDom);
      let option = {
        color: [
          "#4b1aa6",
          "#3d39df",
          "#396bdf",
          "#3298e0",
          "#48b8d0",
          "#56cec7",
          "#87cf99",
          "#7949cf",
          "#b169de",
          "#8b62d1",
        ],
        grid: {
          left: "0",
          right: "0",
          bottom: "0",
          top: "0",
        },
        tooltip: {
          trigger: "item",
          padding: 10,
          borderWidth: 0,
          backgroundColor: "#cccccc80",
          textStyle: {
            color: "#000000",
          },
          formatter(params) {
            console.log("params", params);
            const { label, value } = params.data;
            return `${label}: ${value}单`;
          },
        },
        series: [
          {
            type: "pie",
            radius: [20, 80],
            center: ["50%", "50%"],
            // roseType: "area",
            // roseType: "radius",
            itemStyle: {
              borderRadius: 8,
            },
            label: {
              show: true,
              color: "#152C5B",
              fontSize: 12,
            },
            data: datas || [
              {
                value: 40,
                name: "一期客服楼宇(7,10栋)\n比例:36%",
              },
              {
                value: 38,
                name: "一期客服楼宇(7,11栋)\n比例:36%",
              },
              {
                value: 32,
                name: "一期客服\n(8,10,11栋)-36%",
              },
            ],
          },
        ],
      };
      this.myChart.setOption(option, false);
    },
    displayDatas(datas) {
      let resultList = [];
      let totalCount = 0;
      datas.forEach((item) => {
        resultList.push({
          label: item.inspectionName,
          name: item.inspectionName,
          value: item.totalCount,
        });
        totalCount += item.totalCount;
      });
      this.totalCount = totalCount;
      resultList.forEach((item) => {
        const currentRate = ((item.value / totalCount) * 100).toFixed(2);
        item.name += `: ${currentRate}%`;
      });
      this.initCharts(resultList);
    },
  },
};
</script>

<style lang="less" scoped>
.timeout-work-order {
  height: 270px;
  background: #ffffff;
  border-radius: 10px;
  padding: 14px 20px;
  .chart-container {
    height: 240px;
  }
}
</style>
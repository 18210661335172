var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "order-status-selector" },
    [
      _c(
        "el-select",
        {
          attrs: { size: "mini", placeholder: "请选择" },
          on: { change: _vm.selectChange },
          model: {
            value: _vm.value,
            callback: function ($$v) {
              _vm.value = $$v
            },
            expression: "value",
          },
        },
        _vm._l(_vm.options, function (item) {
          return _c("el-option", {
            key: item.value,
            attrs: { label: item.label, value: item.value },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
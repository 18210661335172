<template>
  <div class="second-relationship-chart" v-loading="chartLoading">
    <div class="second-title">巡检计划名：{{ inspectionName }}</div>
    <div class="chart-container" id="subInspectionChart"></div>
  </div>
</template>

<script>
import { findCountByInspectionName } from "@/api/ruge/bms/rdata/orderInspectionVisualization";
import * as echarts from "echarts";
export default {
  name: "mainRelationshipChart",
  data() {
    return {
      inspectionName: "",
      chartLoading: false,
    };
  },
  mounted() {
    this.$eventBus.$on("subRelationShipFresh", (params) => {
      this.inspectionName = params.inspectionName;
      this.initDatas(params);
    });
  },
  methods: {
    initCharts(xAxisList, valueList) {
      const chartDom = document.getElementById("subInspectionChart");
      this.myChart = echarts.init(chartDom);
      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "none",
          },
          backgroundColor: "#cccccc30",
          textStyle: {
            color: "#000000",
          },
        },
        grid: {
          left: "40",
          right: "10",
          bottom: "20",
        },
        xAxis: {
          type: "category",
          data: xAxisList || [],
          axisLabel: {
            interval: 0,
            showMinLabel: false,
            formatter: (value, index) => {
              return `${value}`;
            },
          },
        },
        yAxis: {
          type: "value",
          name: "子巡检数",
        },
        series: [
          {
            name: "点位数",
            type: "bar",
            barWidth: "32px",
            data: valueList,
            itemStyle: {
              normal: {
                color: "#1A4CEC", // 定义柱形的背景色
                barBorderRadius: [10, 10, 0, 0], // 定义柱形的圆角
              },
            },
          },
        ],
      };
      this.myChart.setOption(option, false);
    },
    initDatas(params) {
      this.chartLoading = true;
      findCountByInspectionName(params)
        .then((res) => {
          let xAxisList = [];
          let valueList = [];
          res.forEach((item) => {
            xAxisList.push(item.positionName);
            valueList.push(item.totalCount);
          });
          this.initCharts(xAxisList, valueList);
        })
        .finally(() => {
          this.chartLoading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.second-relationship-chart {
  .second-title {
    font-size: 14px;
    font-weight: bold;
    color: #525e73;
  }
  .chart-container {
    height: 240px;
  }
}
</style>
import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * 巡检计划/点位/子巡检关系图
 * @param params
 */
export function findInspectionRelationshipDiagram(params) {
  return request({
    url:
      envInfo.bgApp.bmsPath + '/inspection/findInspectionRelationshipDiagram',
    method: 'get',
    params,
  });
}

/**
 * 联动巡检计划名查询
 * @param params
 */
export function findCountByInspectionName(params) {
  return request({
    url: envInfo.bgApp.bmsPath + '/inspection/findCountByInspectionName',
    method: 'get',
    params,
  });
}

/**
 * 各巡检工单类别的月度数量概况
 * @param params
 */
export function findOrderInspectionByStatus(params) {
  return request({
    url: envInfo.bgApp.bmsPath + '/inspection/findOrderInspectionByStatus',
    method: 'get',
    params,
  });
}

/**
 * 各巡检工单类别的月度占比概况
 * @param params
 */
export function findInspectionStatusByMonth(params) {
  return request({
    url: envInfo.bgApp.bmsPath + '/inspection/findInspectionStatusByMonth',
    method: 'get',
    params,
  });
}

/**
 * 各巡检工单类别的不合格工单占比概况
 * @param params
 */
export function findInspectionOffWorkOrders(params) {
  return request({
    url: envInfo.bgApp.bmsPath + '/inspection/findInspectionOffWorkOrders',
    method: 'get',
    params,
  });
}

/**
 * 各巡检工单类别的超时工单占比概况
 * @param params
 */
export function findInspectionOverTimeWorkOrders(params) {
  return request({
    url: envInfo.bgApp.bmsPath + '/inspection/findInspectionOverTimeWorkOrders',
    method: 'get',
    params,
  });
}

/**
 * 巡检接单人月度接单数
 * @param params
 */
export function findInspectionGroupDept(params) {
  return request({
    url: envInfo.bgApp.bmsPath + '/inspection/findInspectionGroupDept',
    method: 'get',
    params,
  });
}

<template>
  <div class="main-relationship-chart" v-loading="chartLoading">
    <div class="chart-container" id="inspectionChart"></div>
  </div>
</template>

<script>
import { findInspectionRelationshipDiagram } from "@/api/ruge/bms/rdata/orderInspectionVisualization";
import * as echarts from "echarts";
export default {
  name: "mainRelationshipChart",
  data() {
    return {
      chartLoading: true,
      resourceData: [],
      currentParams: null,
    };
  },
  mounted() {
    this.$eventBus.$on("orderInspectionVisualization", (params) => {
      this.currentParams = params;
      this.initDatas(params);
    });
  },
  methods: {
    initDatas(params) {
      findInspectionRelationshipDiagram(params)
        .then((res) => {
          console.log("xxx", res);
          this.resourceData = res;
          let xAxisList = [];
          let valueList = [];
          res.forEach((item) => {
            xAxisList.push(item.inspectionName);
            valueList.push(item.totalCount);
          });
          this.initCharts(xAxisList, valueList);
        })
        .finally(() => {
          this.chartLoading = false;
          this.getSubChartDatas(0);
        });
    },
    initCharts(xAxisList, valueList) {
      const chartDom = document.getElementById("inspectionChart");
      this.myChart = echarts.init(chartDom);
      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "none",
          },
          backgroundColor: "#cccccc30",
          textStyle: {
            color: "#000000",
          },
        },
        grid: {
          left: "40",
          right: "10",
          bottom: "20",
        },
        xAxis: {
          type: "category",
          data: xAxisList || [],
          axisLabel: {
            interval: 0,
            showMinLabel: false,
            formatter: (value, index) => {
              return `${value.substring(0, 2)}...`;
            },
          },
        },
        yAxis: {
          type: "value",
          name: "点位数",
        },
        series: [
          {
            name: "点位数",
            type: "bar",
            data: valueList,
            itemStyle: {
              normal: {
                color: "#1A4CEC", // 定义柱形的背景色
                barBorderRadius: [10, 10, 0, 0], // 定义柱形的圆角
              },
            },
          },
        ],
      };
      this.myChart.setOption(option, false);
      this.myChart.getZr().on("click", this.chartClickHander);
    },
    chartClickHander(params) {
      let pointInPixel = [params.offsetX, params.offsetY];
      if (this.myChart.containPixel("grid", pointInPixel)) {
        //点击第几个柱子
        let pointInGrid = this.myChart.convertFromPixel(
          { seriesIndex: 0 },
          pointInPixel
        );
        let xIndex = pointInGrid[0];
        this.getSubChartDatas(xIndex);
      }
    },
    getSubChartDatas(index) {
      if (!this.resourceData.length) return;
      const inspectionName = this.resourceData[index].inspectionName;
      this.$eventBus.$emit("subRelationShipFresh", {
        ...this.currentParams,
        ...{ inspectionName },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.main-relationship-chart {
  .chart-container {
    height: 240px;
  }
}
</style>